<template>
  <v-container id="onboarding-details">
    <v-row justify="center" class="mt-3">
      <v-col cols="12" class="text-center">
        <h4 class="text-h4 font-weight-regular mb-4">Onboarding Details</h4>
        <v-btn style="width: 150px" @click="() => $router.back()" outlined>
          <v-icon>mdi-chevron-left</v-icon> Back
        </v-btn>
        <v-btn style="width: 150px" @click="showGenerateModal = true" class="ml-5" outlined>
          <v-icon>mdi-download-box-outline</v-icon> Download
        </v-btn>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="12" xl="8">
        <v-card :loading="loading">
          <v-list v-if="registration" class="pa-5">
            <v-list-item>
              <v-list-item-subtitle class="item-label">Onboarding ID</v-list-item-subtitle>
              <v-list-item-title>
                <div class="item-value">{{ registration.id || "-" }}</div>
              </v-list-item-title>
            </v-list-item>
            <v-divider />

            <v-list-item v-if="canShowUserInfo()">
              <v-list-item-subtitle class="item-label">User ID</v-list-item-subtitle>
              <v-list-item-title>
                <v-btn outlined :to="{ name: 'user.view', params: { id: registration.userId } }">{{
                  registration.userId || "-"
                }}</v-btn>
              </v-list-item-title>
            </v-list-item>
            <v-divider />

            <v-list-item>
              <v-list-item-subtitle class="item-label"> Onboarding Status </v-list-item-subtitle>
              <v-list-item-title>
                <div class="item-value">{{ registration.status || "-" }}</div>
              </v-list-item-title>
            </v-list-item>
            <v-divider />

            <v-list-item>
              <v-list-item-subtitle class="item-label">Registered At</v-list-item-subtitle>
              <v-list-item-title>
                <div class="item-value">{{ formatDate(registration.createdAt) || "-" }}</div>
              </v-list-item-title>
            </v-list-item>
            <v-divider />

            <v-list-item>
              <v-list-item-subtitle class="item-label">Mobile Number</v-list-item-subtitle>
              <v-list-item-title>
                <div class="item-value">{{ registration.mobileNo || "-" }}</div>
              </v-list-item-title>
            </v-list-item>
            <v-divider />

            <v-list-item>
              <v-list-item-subtitle class="item-label">NID No</v-list-item-subtitle>
              <v-list-item-title>
                <div class="item-value">{{ registration.nid || "-" }}</div>
              </v-list-item-title>
            </v-list-item>
            <v-divider />

            <v-list-item>
              <v-list-item-subtitle class="item-label">Date Of Birth</v-list-item-subtitle>
              <v-list-item-title>
                <div class="item-value">{{ registration.dateOfBirth || "-" }}</div>
              </v-list-item-title>
            </v-list-item>
            <v-divider />

            <v-list-item class="ma-0 pa-0" v-if="compareData">
              <v-data-table
                class="my-5 compare-table"
                elevation="10"
                :headers="compareDataHeaders"
                :items="compareData"
                disable-sort
                disable-filtering
                disable-pagination
                hide-default-footer
              />
            </v-list-item>
            <v-divider />

            <v-list-item>
              <v-list-item-subtitle class="item-label"> NID Card (Front) </v-list-item-subtitle>
              <v-list-item-title>
                <div class="item-value">
                  <img
                    alt="nid-front-image"
                    :src="`data:image/jpg;base64,${registration.nidFrontB64}`"
                  />
                </div>
              </v-list-item-title>
            </v-list-item>
            <v-divider />

            <v-list-item>
              <v-list-item-subtitle class="item-label"> NID Card (Back) </v-list-item-subtitle>
              <v-list-item-title>
                <div class="item-value">
                  <img
                    alt="nid-back-image"
                    :src="`data:image/jpg;base64,${registration.nidBackB64}`"
                  />
                </div>
              </v-list-item-title>
            </v-list-item>
            <v-divider />

            <v-list-item>
              <v-list-item-subtitle class="item-label">
                Applicant's First Name
              </v-list-item-subtitle>
              <v-list-item-title>
                <div class="item-value">{{ registration.firstName || "-" }}</div>
              </v-list-item-title>
            </v-list-item>
            <v-divider />

            <v-list-item>
              <v-list-item-subtitle class="item-label">
                Applicant's Last Name
              </v-list-item-subtitle>
              <v-list-item-title>
                <div class="item-value">{{ registration.lastName || "-" }}</div>
              </v-list-item-title>
            </v-list-item>
            <v-divider />

            <v-list-item>
              <v-list-item-subtitle class="item-label"> Father's Name </v-list-item-subtitle>
              <v-list-item-title>
                <div class="item-value">{{ registration.fatherName || "-" }}</div>
              </v-list-item-title>
            </v-list-item>
            <v-divider />

            <v-list-item>
              <v-list-item-subtitle class="item-label"> Mother's Name </v-list-item-subtitle>
              <v-list-item-title>
                <div class="item-value">{{ registration.motherName || "-" }}</div>
              </v-list-item-title>
            </v-list-item>
            <v-divider />

            <v-list-item>
              <v-list-item-subtitle class="item-label"> Spouse's Name </v-list-item-subtitle>
              <v-list-item-title>
                <div class="item-value">{{ registration.spouseName || "-" }}</div>
              </v-list-item-title>
            </v-list-item>
            <v-divider />

            <v-list-item>
              <v-list-item-subtitle class="item-label"> Nominee's Name </v-list-item-subtitle>
              <v-list-item-title>
                <div class="item-value">{{ registration.nomineeName || "-" }}</div>
              </v-list-item-title>
            </v-list-item>
            <v-divider />

            <v-list-item>
              <v-list-item-subtitle class="item-label"> Present Address </v-list-item-subtitle>
              <v-list-item-title>
                <div class="item-value">{{ registration.presentAddress || "-" }}</div>
              </v-list-item-title>
            </v-list-item>
            <v-divider />

            <v-list-item>
              <v-list-item-subtitle class="item-label"> Permanent Address </v-list-item-subtitle>
              <v-list-item-title>
                <div class="item-value">
                  {{ registration.permanentAddress || "-" }}
                </div>
              </v-list-item-title>
            </v-list-item>
            <v-divider />

            <v-list-item>
              <v-list-item-subtitle class="item-label"> Postal Code </v-list-item-subtitle>
              <v-list-item-title>
                <div class="item-value">{{ registration.postalCode || "-" }}</div>
              </v-list-item-title>
            </v-list-item>
            <v-divider />

            <v-list-item>
              <v-list-item-subtitle class="item-label"> Blood Group </v-list-item-subtitle>
              <v-list-item-title>
                <div class="item-value">{{ registration.bloodGroup || "-" }}</div>
              </v-list-item-title>
            </v-list-item>
            <v-divider />

            <v-list-item>
              <v-list-item-subtitle class="item-label"> Gender </v-list-item-subtitle>
              <v-list-item-title>
                <div class="item-value">{{ registration.gender || "-" }}</div>
              </v-list-item-title>
            </v-list-item>
            <v-divider />

            <v-list-item>
              <v-list-item-subtitle class="item-label"> Profession </v-list-item-subtitle>
              <v-list-item-title>
                <div class="item-value">{{ registration.profession || "-" }}</div>
              </v-list-item-title>
            </v-list-item>
            <v-divider />

            <v-list-item>
              <v-list-item-subtitle class="item-label"> Email </v-list-item-subtitle>
              <v-list-item-title>
                <div class="item-value">{{ registration.email || "-" }}</div>
              </v-list-item-title>
            </v-list-item>
            <v-divider />

            <v-list-item>
              <v-list-item-subtitle class="item-label"> Status Message </v-list-item-subtitle>
              <v-list-item-title>
                <div class="item-value">{{ registration.statusMessage || "-" }}</div>
              </v-list-item-title>
            </v-list-item>
            <v-divider />

            <v-list-item>
              <v-list-item-subtitle class="item-label"> Referral </v-list-item-subtitle>
              <v-list-item-title>
                <div class="item-value">{{ registration.referral || "-" }}</div>
              </v-list-item-title>
            </v-list-item>
            <v-divider />

            <v-list-item>
              <v-list-item-subtitle class="item-label"> Profile Photo </v-list-item-subtitle>
              <v-list-item-title>
                <div class="item-value">
                  <img :src="`data:image/jpg;base64,${registration.profilePhotoB64}`" />
                </div>
              </v-list-item-title>
            </v-list-item>
            <v-divider />

            <v-list-item>
              <v-list-item-subtitle class="item-label"> Face Liveness Score </v-list-item-subtitle>
              <v-list-item-title>
                <div class="item-value">{{ 100 * registration.livenessScore || "N/A" }}</div>
              </v-list-item-title>
            </v-list-item>
            <v-divider />

            <v-list-item>
              <v-list-item-subtitle class="item-label"> Face Match Score </v-list-item-subtitle>
              <v-list-item-title>
                <div class="item-value">
                  {{ 100 * registration.faceMatchScore || "N/A" }}
                </div>
              </v-list-item-title>
            </v-list-item>
            <v-divider />

            <v-list-item>
              <v-list-item-subtitle class="item-label">
                Applicant's Name Match Score
              </v-list-item-subtitle>
              <v-list-item-title>
                <div class="item-value">
                  {{ registration.nameMatchScore || "N/A" }}
                </div>
              </v-list-item-title>
            </v-list-item>
            <v-divider />

            <v-list-item>
              <v-list-item-subtitle class="item-label">
                Father's Name Match Score
              </v-list-item-subtitle>
              <v-list-item-title>
                <div class="item-value">
                  {{ registration.fatherNameMatchScore || "N/A" }}
                </div>
              </v-list-item-title>
            </v-list-item>
            <v-divider />
            <v-list-item>
              <v-list-item-subtitle class="item-label">
                Mother's Name Match Score
              </v-list-item-subtitle>
              <v-list-item-title>
                <div class="item-value">
                  {{ registration.motherNameMatchScore || "N/A" }}
                </div>
              </v-list-item-title>
            </v-list-item>
            <v-divider />
            <v-list-item>
              <v-list-item-subtitle class="item-label">
                Spouse Name Match Score
              </v-list-item-subtitle>
              <v-list-item-title>
                <div class="item-value">
                  {{ registration.spouseNameMatchScore || "N/A" }}
                </div>
              </v-list-item-title>
            </v-list-item>
            <v-divider />
            <v-list-item>
              <v-list-item-subtitle class="item-label"> Nominee Photo </v-list-item-subtitle>
              <v-list-item-title>
                <div class="item-value">
                  <img
                    :src="`data:image/jpg;base64,${registration.nomineePhotoB64}`"
                    v-if="registration.nomineePhotoB64"
                  />
                  <span v-else>&mdash;</span>
                </div>
              </v-list-item-title>
            </v-list-item>
            <v-divider />

            <v-list-item>
              <v-list-item-subtitle class="item-label">
                Relationship with Nominee
              </v-list-item-subtitle>
              <v-list-item-title>
                <div class="item-value">{{ registration.nomineeRelation || "-" }}</div>
              </v-list-item-title>
            </v-list-item>
            <v-divider />

            <v-list-item>
              <v-list-item-subtitle class="item-label">
                Applicant's Signature
              </v-list-item-subtitle>
              <v-list-item-title>
                <div class="item-value">
                  <img
                    style="max-height: 100px"
                    :src="`data:image/jpg;base64,${registration.signature}`"
                    v-if="registration.signature"
                    alt="signature"
                  />
                  <span v-else>&mdash;</span>
                </div>
              </v-list-item-title>
            </v-list-item>
            <v-divider />

            <v-list-item v-if="canShowNidOcrCard()">
              <v-list-item-subtitle class="item-label">OCR Request ID</v-list-item-subtitle>
              <v-list-item-title>
                <v-btn
                  outlined
                  :to="{
                    name: 'nid-ocr-card.single',
                    params: { requestId: registration.ocrRequestId },
                  }"
                >
                  {{ registration.ocrRequestId || "-" }}
                </v-btn>
              </v-list-item-title>
            </v-list-item>
            <v-divider />

            <v-list-item v-if="canShowLivenessRequest()">
              <v-list-item-subtitle class="item-label">Liveness Request ID</v-list-item-subtitle>
              <v-list-item-title>
                <v-btn
                  outlined
                  :to="{
                    name: 'liveness-request.single',
                    params: { requestId: registration.livenessRequestId },
                  }"
                >
                  {{ registration.livenessRequestId || "-" }}
                </v-btn>
              </v-list-item-title>
            </v-list-item>
            <v-divider />

            <v-list-item v-if="canShowMatchRequest()">
              <v-list-item-subtitle class="item-label">Match Request ID</v-list-item-subtitle>
              <v-list-item-title>
                <v-btn
                  outlined
                  :to="{
                    name: 'match-request.single',
                    params: { requestId: registration.matchRequestId },
                  }"
                >
                  {{ registration.matchRequestId || "-" }}
                </v-btn>
              </v-list-item-title>
            </v-list-item>
            <v-divider />

            <v-list-item>
              <v-list-item-subtitle class="item-label">AML Request ID</v-list-item-subtitle>
              <v-list-item-title>
                {{ registration.amlRequestId || "-" }}
              </v-list-item-title>
            </v-list-item>
          </v-list>

          <v-card-text class="text-center grey--text py-15" v-else>
            <v-progress-circular indeterminate size="44" />
            <div class="caption my-2">Loading onboarding details...</div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-if="registration && registration.userId"
      v-model="showGenerateModal"
      max-width="600px"
      scrollable
    >
      <!--      Here nested v-if used, because the element should be recreated everytime
to reset components internal data. Otherwise multiple clicks can result to infinite loading -->
      <generate-report-single
        v-if="showGenerateModal"
        :user-id="registration.userId"
        @close="showGenerateModal = false"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import {
  NID_OCR_CARD_SINGLE,
  NID_SCRAPER_INFO,
  ONBOARDING_MFS_GET_BY_UID,
  REPORT_GET_REGISTRATION_BY_USER,
} from "@/constants/api";
import GenerateReportSingle from "@/views/pages/dashboard/mfs/GenerateReportSingle";

export default {
  name: "MFSView",
  metaInfo: { title: "Onboarding Details" },
  components: { GenerateReportSingle },
  data: () => ({
    loading: false,
    registration: null,
    nidData: null,
    ocrParsedData: null,
    compareData: null,
    csvFileName: "nothing.csv",
    downloadableData: [],
    showGenerateModal: false,
  }),
  computed: {
    onboardId() {
      return Number(this.$route.params.id);
    },
    compareDataHeaders: () => [
      { text: "Field", value: "fieldName" },
      { text: "OCR Data", value: "ocrData" },
      { text: "NID Data", value: "nidData" },
      { text: "Edited Data", value: "finalData" },
      { text: "Matching %", value: "matching" },
    ],
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    emptyChecker(val, placeholder = "-") {
      return val ? val : placeholder;
    },
    async fetchData() {
      try {
        this.loading = true;
        await this.fetchRegistration();
      } catch (err) {
        console.error(err);
        this.$iziToast.showError(err);
        this.$router.push({ name: "mfs.index" });
      }
      try {
        // do not go to back page if the following calls failed
        await this.fetchNidData();
        await this.fetchOcrParsedData();
        await this.constructCompareData();
      } catch (e) {
        //
      }
      await this.$nextTick();
      this.loading = false;
    },
    async fetchRegistration() {
      const response = await this.$axios.get(`${ONBOARDING_MFS_GET_BY_UID}/${this.onboardId}`);
      if (response.data.code !== 0) {
        this.registration = {};
        throw new Error(response.data.message);
      }
      this.registration = response.data.data;
    },
    async fetchNidData() {
      let formData = new FormData();
      formData.append("nid", this.registration.nid);
      formData.append("dob", this.registration.dateOfBirth);
      const response = await this.$axios.post(NID_SCRAPER_INFO, formData);

      if (response.data.code !== 0) {
        this.nidData = {};
        throw new Error(response.data.message);
      }
      this.nidData = this.emptyChecker(response.data.data, {});
    },
    async fetchOcrParsedData() {
      const response = await this.$axios.get(
        `${NID_OCR_CARD_SINGLE}?requestId=${this.registration.ocrRequestId}`
      );

      if (response.data.code !== 0) {
        this.ocrParsedData = {};
        throw new Error(response.data.message);
      }
      this.ocrParsedData = this.emptyChecker(response.data.data, {});
    },
    async constructCompareData() {
      this.compareData = [
        {
          fieldName: "Name",
          ocrData: this.emptyChecker(this.ocrParsedData.nameEn),
          nidData: this.emptyChecker(this.nidData.nameEn),
          finalData: this.emptyChecker(this.registration.firstName + this.registration.lastName),
          matching: this.registration.nameMatchScore,
        },
        {
          fieldName: "Father Name (EN)",
          ocrData: this.emptyChecker(this.ocrParsedData.fatherEn),
          nidData: this.emptyChecker(this.nidData.fatherNameEn),
          finalData: this.emptyChecker(this.registration.fatherName),
          matching: this.registration.fatherNameMatchScore,
        },
        {
          fieldName: "Mother Name (EN)",
          ocrData: this.emptyChecker(this.ocrParsedData.motherEn),
          nidData: this.emptyChecker(this.nidData.motherNameEn),
          finalData: this.emptyChecker(this.registration.motherName),
          matching: this.registration.motherNameMatchScore,
        },
        {
          fieldName: "NID No",
          ocrData: this.emptyChecker(this.ocrParsedData.nid),
          nidData: this.emptyChecker(this.nidData.nid),
          finalData: this.emptyChecker(this.registration.nid),
          matching: null,
        },
        {
          fieldName: "Date Of Birth",
          ocrData: this.emptyChecker(this.ocrParsedData.dob),
          nidData: this.emptyChecker(this.nidData.dateOfBirth),
          finalData: this.emptyChecker(this.registration.dateOfBirth),
          matching: null,
        },
        {
          fieldName: "Present Address",
          ocrData: this.emptyChecker(null),
          nidData: this.emptyChecker(this.nidData.presentAddressEn.addressLine),
          finalData: this.emptyChecker(this.registration.presentAddress),
          matching: null,
        },
        {
          fieldName: "Permanent Address",
          ocrData: this.emptyChecker(null),
          nidData: this.emptyChecker(this.nidData.permanentAddressEn.addressLine),
          finalData: this.emptyChecker(this.registration.permanentAddress),
          matching: null,
        },
        // Bangla name removed as bangla name is not present on final data
        // and no match score
      ];
    },
    async downloadPdf() {
      if (!this.registration.userId) {
        return this.$iziToast.error({
          title: "User id not found",
        });
      }
      try {
        const response = await this.$axios.get(
          REPORT_GET_REGISTRATION_BY_USER.replace(":userId:", this.registration.userId)
        );
        if (response.data.code !== 0) {
          throw new Error(response.data.message);
        }

        const state = response.data.data.state;
        if (state !== "COMPLETED") {
          throw new Error(response.data.data.message);
        }

        const data = encodeURIComponent(response.data.data.content);
        const filename = `${this.registration.firstName} ${this.registration.lastName}.pdf`;

        const element = document.createElement("a");
        element.setAttribute("href", `data:application/pdf;base64,${data}`);
        element.setAttribute("download", filename);
        element.style.display = "none";
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
      } catch (err) {
        this.$iziToast.showError(err);
      }
    },
  },
};
</script>

<style lang="scss">
#onboarding-details {
  .item-label {
    max-width: 225px;
    padding-right: 15px;
    text-align: right;
    white-space: pre-wrap;
  }
  .item-value {
    padding: 15px;
    white-space: pre-wrap;
    line-height: 24px;
    img {
      width: 100%;
      max-height: 200px;
      object-fit: contain;
      object-position: center left;
    }
  }
  .compare-table {
    width: 100%;
    padding: 0.5rem;
    margin: 0;
    border: 1px solid #c6c6c6;
  }
}
</style>
