<template>
  <v-card>
    <v-card-text class="text-center grey--text py-15">
      <v-progress-circular indeterminate size="44" />
      <div class="caption my-2">Creating report ...</div>
    </v-card-text>
    <v-card-actions class="row justify-center mb-5">
      <v-btn color="warning" @click="$emit('close')"> Close</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import moment from "moment";
import { REPORT_GENERATE, REPORT_GET_REPORT } from "@/constants/api";

export default {
  name: "GenerateReportSingle",
  props: {
    userId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      reportId: null,
    };
  },
  mounted() {
    this.downloadReportForSingleUser();
  },
  methods: {
    async downloadReportForSingleUser() {
      this.loading = true;
      try {
        const response = await this.$axios.post(REPORT_GENERATE + this.userId);
        if (response.data.code !== 0) {
          throw new Error(response.data.message);
        }
        this.reportId = response.data.data.reportId;
        await this.fetchReport();
      } catch (e) {
        this.$iziToast.showError(e);
        this.loading = false;
      }
    },
    async fetchReport() {
      if (!this.reportId) {
        return;
      }
      this.loading = true;
      try {
        const response = await this.$axios.get(REPORT_GET_REPORT + "?reportId=" + this.reportId);
        // console.log(response);
        if (response.data.code !== 0) {
          throw new Error(response.data.message);
        }
        console.log("State:", response.data.data.state);
        if (response.data.data.state === "COMPLETED") {
          // console.log(response.data.data);
          if (response.data.data.content) {
            await this.downloadPdfFile(
              response.data.data.content,
              "report_" + moment().toISOString()
            );
          } else {
            throw new Error("PDF can not be generated");
          }
          this.loading = false;
        } else {
          setTimeout(this.fetchReport, 3000);
        }
      } catch (e) {
        this.$iziToast.showError(e, "Can not generate report");
      }
    },
    downloadPdfFile(data, name) {
      let aTag = window.document.createElement("a");
      aTag.href = "data:application/pdf;base64," + data;
      aTag.download = `${name}.pdf`;
      document.body.appendChild(aTag);
      aTag.click();
      document.body.removeChild(aTag);
      this.$emit("close");
    },
  },
};
</script>

<style scoped></style>
